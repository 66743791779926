@import "./../../../styles/variables";

.email-info-container {
  @media (max-width: 765px) {
    padding: 0 24px 0 24px;
  }
}

@media (min-width: 765px) {
  .email-info-container {
    width: 684px;
    padding: 0px 24px 104px 24px;
  }
}

@mixin emailInfoCommons() {
  @media (max-width: 765px) {
    // width: 327px;
  }
  border-radius: 40px;
  border: none;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  color: $textColor;
}
.email-info-container {
  padding: 0 24px 0 24px;
}

.email-info-heading {
  @include emailInfoCommons();
  margin: 0px;
  height: 40px;
  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
}
.email-info-section {
  padding-top: 32px;
}

.email-Info-error-message {
  box-sizing: border-box;
  @media (min-width: 784px) {
    // height: 112px;
    width: 624px;
  }
  border: 2px solid $yellow;
  border-radius: 4px;
  background-color: $buttonWhite;
  margin-bottom: 54px;
}

.email-Info-error-content {
  padding: 24px 0 8px 24px;
}
.email-Info-alert-icon {
  @media (min-width: 784px) {
    padding: 60px 10px 30px 35px;
  }
  background-color: $yellow;
  align-items: center;
}

.email-Info-error-text {
  color: $errorText;
  font-family: $font;
  font-size: 16px;
  font-weight: bold;
}

.email-Info-error-desc {
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
}

.email-Info-close {
  @media (min-width: 784px) {
    padding-left: 75px;
  }
}

.bg1 {
  margin-top: 8px;
}
.attestation-heading {
  @include emailInfoCommons();
  height: 40px;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
}

.input-align {
  padding-bottom: 32px;
}

.text-field {
  height: 152px;
}

.text-field-1 {
  height: auto;
  margin-top: 32px;
}

.text-field-2 {
  height: auto;
  margin-top: 32px;
  margin-bottom: 32px;
}

.text-field-copy-3 {
  height: 88px;
  margin-top: 32px;
}

.attestation-info {
  font-size: 24px;
}
.back-button-email-Info {
  @include emailInfoCommons();
  @media (max-width: 765px) {
    height: 60px;
    width: 248px;
    margin-top: 20px;
  }
  color: $buttonBlue;
  max-width: 248px;
  border: 2px solid $buttonBlue;
  background-color: $buttonWhite;
}

.next-button-email-Info {
  @include emailInfoCommons();
  @media (max-width: 765px) {
    height: 60px;
    width: 248px;
    margin-top: 20px;
  }
  box-sizing: border-box;
  background-color: $buttonBlue;
  color: $buttonWhite;
  margin-top: 0px;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.checkbox {
  float: left;
}

@media (min-width: 765px) {
  .email-info-section {
    width: 684px;
  }
  .new-address-copy,
  .text-field-copy-3 {
    width: 400px;
  }

  .back-button-shipping-Info,
  .next-button-shipping-Info {
    height: 56px;
    width: 160px;
  }
  .bg,
  .cc-form,
  .delivery-options,
  .confirmation-email,
  .checkbox-error {
    width: 400px;
  }
}
.checkbox-shipping {
  @include emailInfoCommons();
  text-align: left;
}
.checkbox {
  margin-top: 5px;
}
.checkbox-attestation {
  @include emailInfoCommons();
  padding-left: 40px;
  text-align: left;
  width: 85%;
}

.shipping-amount {
  font-weight: bold;
}
